/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";

const AtlantaHeartfulnessDiwaliCelebration = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Donation HI, USA";
    return `<gatsby_donation donationId =  ${id} btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Atlanta Heart Notes, USA" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("atlanta_heart_notes,_USA", event),
    []
  );

  const renderContentHeader = () => (
    <Box
      sx={{
        backgroundColor: "rgb(223, 242, 246)",
        textAlign: "center!important",
        marginBottom: "30px",
        padding: "40px 0px",
        border: "1px solid rgb(211, 213, 210)",
      }}
    >
      <h1 sx={{ fontSize: "2.3em" }}>Heartfulness Donations</h1>
    </Box>
  );

  return (
    <LayoutHome
      contentHeader={renderContentHeader()}
      seoTitle="Atlanta Heartfulness Diwali Celebration - Donations"
    >
      <Grid
        columns={1}
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        <Box>
          <div style={{ lineHeight: "1.1", marginBottom: "8px" }}>
            <h1
              style={{
                color: "#2d3550",
                fontSize: "1.5em",
                fontWeight: "600",
                marginTop: "20px",
              }}
            >
              Atlanta Heartfulness Diwali Celebration
            </h1>
          </div>
        </Box>
        <Box>
          <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
            All donations to Shri Ram Chandra Mission are voluntary. Shri Ram
            Chandra Mission is registered as a tax-exempt organization under
            section 501-C3 of the Internal Revenue Code. Donated funds are used
            for:
          </p>
          <ul>
            <li>
              <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                &#x2022; Maintenance and support of existing ashrams and
                meditation centers across USA as well as the building and
                establishment of new SRCM facilities.
              </p>
            </li>
            <li>
              <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                &#x2022; Hosting of seminars, training courses, and other
                activities at SRCM facilities.
              </p>
            </li>
            <li>
              <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                &#x2022; Printing and distribution of publications and other
                multimedia content.
              </p>
            </li>
            <li>
              <p style={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                &#x2022; Support research, education and training courses to
                promote Sahaj Marg Heartfulness meditation and practice.
              </p>
            </li>
          </ul>
        </Box>
        <Box>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent(17, "Donate Now")}
          />
        </Box>
      </Grid>
    </LayoutHome>
  );
};

export default AtlantaHeartfulnessDiwaliCelebration;
